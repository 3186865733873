<template>
  <div>
    <v-text-field
      id="loginEmailInput"
      label="E-Mail"
      :rules="emailRules"
      outlined
      v-model="email"
      autofocus
      validate-on-blur
    />
    <v-text-field
      id="loginPasswordInput"
      label="Passwort"
      :type="show ? 'text' : 'password'"
      outlined
      v-model="password"
      validate-on-blur
      :append-icon="show ? 'mdi-eye' : 'mdi-eye-off'"
      @click:append="show = !show"
    />
    <v-text-field
      id="loginTFAInput"
      label="Zwei-Faktor-Code"
      outlined
      v-model="tfaCode"
      validate-on-blur
    />
  </div>
</template>

<script>
import change from "@/helper/change";
import emailRegex from "@/helper/emailRegex";

export default {
  data() {
    return {
      show: false,
      emailRules: [
        (value) => !!value || "Bitte geben Sie eine E-Mail-Adresse ein.",
        (value) =>
          emailRegex.test(value) ||
          "Bitte geben Sie eine valide E-Mail-Adresse ein.",
      ],
    }
  },
  computed: {
    email: {
      get() {
        return this.$store.state.form.values.loginForm?.email;
      },
      set(value) {
        change(this.$store, "loginForm", "email", value);
      },
    },
    password: {
      get() {
        return this.$store.state.form.values.loginForm?.password || "";
      },
      set(value) {
        change(this.$store, "loginForm", "password", value);
      },
    },
    tfaCode: {
      get() {
        return this.$store.state.form.values.loginForm?.tfaCode;
      },
      set(value) {
        change(this.$store, "loginForm", "tfaCode", value);
      },
    },        
  }
};
</script>
