<template>
  <v-app>
    <v-container fluid class="form">
      <v-row class="form">
        <v-spacer />
        <v-col align-self="center" cols="12" sm="8" lg="6" xl="4">
          <v-form ref="loginForm" @submit="onSubmit">
            <v-card shaped>
              <v-card-text>
                <LoginFields />
              </v-card-text>
              <v-card-actions>
                <v-btn
                  class="mb-3 mr-3"
                  color="primary"
                  id="loginSubmitButton"
                  type="submit"
                  @click="onSubmit"
                >
                  Anmelden
                </v-btn>        
                {{loginError}}  
              </v-card-actions>
            </v-card>
          </v-form>
        </v-col>
        <v-spacer />
      </v-row>
    </v-container>
  </v-app>
</template>

<script>
import LoginFields from "@/components/login/LoginFields";

export default {
  components: {
    LoginFields
  },
  created() {
    this.$recaptchaLoaded().then(() => {
      this.$recaptchaInstance.showBadge();
    });
  },  
  computed: {
    loginError() {
      return this.$store.state.auth.loginError || ""
    }
  },
  methods: {
    async onSubmit(event) {
      event.preventDefault()
      if (this.$refs.loginForm.validate()) {
        const recaptchaCode = await this.$recaptcha("login");
        const values = {
          ...this.$store.state.form.values.loginForm,
          recaptchaCode,
        };
        this.$store
        .dispatch("loginAPI", values)
        .then(() => {
          this.$router.push("/").catch(() => {})
        })   
        .catch(() => {})    
      }
    }
  },
}

</script>
